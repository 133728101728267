<template>
    <div class="profitPlan">
        <el-form label-position="right" label-width="80px">
            <el-row>
                <el-col :span="8">
                    <el-form-item label="专家名称">
                        <el-select filterable v-model="hiddenUid" placeholder="请选择">
                            <el-option
                            v-for="item in expertsOptions"
                            :key="item.uid"
                            :label="item.nick_name"
                            :value="item.uid + '&' + item.period_id + '&' + item.period_no + '&' + item.phase">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="期号">
                        <el-input disabled v-model="period_no"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="场次">
                        <el-input disabled v-model="phase"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <predicItemLq ref="firstMatchs" :data="matchList" @setRes="setFirstRes"></predicItemLq>
            <predicItemLq ref="secondMatchs" :data="matchList" @setRes="setSecondRes"></predicItemLq>
        </el-form>
        <p class="return">预计回报：{{return_rate}}</p>
        <div class="footer">
            <span @click="reset">取消</span>
            <span @click="save">确定</span>
        </div>
        <el-dialog
            title="提示"
            :visible.sync="dialogVisible"
            width="30%"
        >
            <span>当前场次已有方案，是否继续添加？</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="save">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import api from '../../../service/api';
import predicItemLq from "../../../components/zjq/predictItemLq.vue";
export default {
    name: 'profitPlan',
    components: {
        predicItemLq
    },
    data () {
        return {
            dialogVisible: false,
            matchList: [],
            expertsOptions: [],
            hiddenUid: '',
            uid: '', // 专家
            period_id: '',
            period_no: '', // 期次
            phase: '', // 场次
            firstMatch: {},
            secondMatch: {}
        }
    },
    computed: {
        return_rate () {
            if (this.firstMatch.play_type && this.secondMatch.play_type) {
                return parseInt(parseFloat(this.firstMatch.firstOdds) * parseFloat(this.secondMatch.firstOdds) * 100) / 100
            } else {
                return '-'
            }
        }
    },
    created () {
        this.initExpertsList()
    },
    watch: {
        hiddenUid () {
            const arr = this.hiddenUid.split('&');
            this.uid = arr[0];
            this.period_id = arr[1];
            this.period_no = arr[2];
            this.phase = arr[3];
            this.initMatchList()
        }
    },
    methods: {
        // 保存 TODO 校验参数
        async save () {
            this.dialogVisible = false;
            if (!(this.firstMatch.play_type && this.secondMatch.play_type)) {
                this.$message.error('请选择两场比赛！')
                return
            }
            if (this.firstMatch.match_id === this.secondMatch.match_id) {
                this.$message.error('不能选择同一场比赛！')
                return
            }
            const param = {
                plan_uid: this.uid,
                period_id: this.period_id,
                phase: this.phase,
                return_rate: this.return_rate,
                rel_type: '11'
            }
            param.match_info_arr = [];
            param.match_info_arr.push(this.firstMatch);
            param.match_info_arr.push(this.secondMatch);
            const minOdds = (this.return_rate + '').split('-')[0];
            if (parseFloat(minOdds) < 2) {
                this.$message.error('赔率必须大于2！')
                return
            }
            param.return_rate = this.return_rate;
            const res = await api.profitPlanPublish(param)
            if (res.code === 0) {
                this.$message({
                    type: 'sucess',
                    message: '添加成功！'
                })
                window.parent.location.href = 'https://dpi.zjqvv.com/op-admin/expert-plan/period'
            } else {
                alert(res.msg)
            }
        },
        // 重置状态
        reset () {
            this.firstMatch = {};
            this.secondMatch = {};
            this.$refs.firstMatchs.match_id = '';
            this.$refs.secondMatchs.match_id = '';
        },
        setFirstRes (obj) {
            this.firstMatch = obj
        },
        setSecondRes (obj) {
            this.secondMatch = obj
        },
        // 初始化比赛列表
        async initMatchList () {
            this.reset();
            const res = await api.initProfitMatchList({
                plan_uid: this.uid
            })
            if (res.code === 0) {
                this.matchList = res.data || []
            } else {
                this.matchList = []
                alert(res.msg)
            }
        },
        async initExpertsList () {
            const res = await api.getExpertsList({
                rel_type: '11'
            })
            if (res.code === 0) {
                this.expertsOptions = res.data;
            } else {
                alert(res.msg)
            }
        }
    }
}
</script>

<style lang="less" scoped>
.profitPlan{
    padding: 20px;
    text-align: left;
    .return{
        text-align: center;
        font-size: 14px;
        color: red;
        margin-top: 50px;
    }
    .footer{
        width: 100%;
        margin-top: 40px;
        text-align: center;
        span{
            display: inline-block;
            margin-right: 20px;
            width: 120px;
            height: 40px;
            line-height: 40px;
            border-radius: 8px;
            background-color: #eee;
            cursor: pointer;
        }
    }
}
</style>
