<template>
    <div class="predictBox">
        <el-form-item label="选择赛事">
            <el-select style="width: 100%" v-model="match_id" filterable placeholder="请选择">
                <el-option
                v-for="item in data"
                :key="item.match_id"
                :label="item.match_text"
                :value="item.match_id">
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="">
            <div class="predictBox">
                <div v-for="(item, index) in odds" :key="'odds'+index" class="item">
                    <p v-if="item.type_id==2">主0</p>
                    <p v-else>主{{item.ovalue}}</p>
                    <p
                        @click="itemClick(item.type_id, '2', item.current_right)"
                        class="predict"
                        :class="{'active':play_type == item.type_id && (predict_result == '2' || second_predict_result == '2')}"
                    >
                        <em>{{item.current_right}}</em>
                        <span>主负</span>
                    </p>
                    <p
                        @click="itemClick(item.type_id, '1', item.current_left)"
                        class="predict"
                        :class="{'active':play_type == item.type_id && (predict_result == 1 || second_predict_result == 1)}"
                    >
                        <em>{{item.current_left}}</em>
                        <span>主胜</span>
                    </p>
                </div>
            </div>
        </el-form-item>
    </div>
</template>

<script>
export default {
    name: 'predictItem',
    props: ['data'],
    data () {
        return {
            match_id: '',
            odds: [],
            play_type: '',
            predict_result: '',
            first_odds: '',
            second_predict_result: '',
            second_odds: ''
        }
    },
    watch: {
        match_id () {
            this.reset();
            this.odds = [];
            this.second_predict_result = '';
            for (let i = 0; i < this.data.length; i++) {
                if (this.data[i].match_id === this.match_id) {
                    this.odds = this.data[i].odds
                }
            }
        }
    },
    methods: {
        reset () {
            this.play_type = '';
            this.predict_result = '';
            this.first_odds = '';
            this.second_predict_result = '';
            this.second_odds = '';
        },
        // TODO reset
        itemClick (type, res, odds) {
            this.play_type = type;
            this.predict_result = res;
            this.first_odds = odds
            this.$emit('setRes', {
                match_id: this.match_id,
                play_type: this.play_type,
                predict_result: this.predict_result,
                firstOdds: this.first_odds,
                second_predict_result: this.second_predict_result,
                second_odds: this.second_odds
            })
        }
    }
}
</script>

<style lang="less" scoped>
.predictBox{
    .predictBox{
        border: 1px solid #fff;
        display: flex;
        flex-direction: column-reverse;
        .item{
            width: 100%;
            height: 40px;
            background-color: #f2f2f2;
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #fff;
            p{
                width: 33.33%;
                text-align: center;
                border-left: 1px solid #fff;
                font-weight: bold;
            }
            p.predict{
                cursor: pointer;
                &:hover{
                    background-color: rgb(245, 92, 92);
                }
            }
            .active{
                background-color: red;
                color: #fff;
            }
        }
    }
}
</style>
